const FALLBACK_TARGET = '/account'

export default defineNuxtRouteMiddleware(({ fullPath, name, query }) => {
  if (import.meta.server) return // fallback if route is not ssr: false in nuxt.config routeRules
  const { isLoggedIn } = useAuth()
  if (name === 'login' && isLoggedIn.value) {
    const target = decodeRedirectQuery(query) || FALLBACK_TARGET
    return navigateTo(target)
  }

  if (name !== 'login' && !isLoggedIn.value) {
    return navigateTo(createRedirectLocation({ name: 'login' }, fullPath))
  }
})
